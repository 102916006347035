import { ethers } from 'ethers';


export const ABI_ERC20 = [
	'function balanceOf(address owner) view returns (uint256)',
	'function decimals() view returns (uint8)',
	'function symbol() view returns (string)',
	'function transfer(address to, uint amount) returns (bool)',
	'function transferFrom(address _from, address _to, uint _value) external returns (bool)',
	'function approve(address _spender, uint _value)',
	'function allowance(address _owner, address _spender) public view returns (uint remaining)'
]

export const ABI_ERC721 = [
	'function ownerOf(uint256 tokenId) view returns (address)',
	'function tokenOfOwnerByIndex(address owner, uint256 index) public view  returns (uint256)',
	'function totalSupply() public view  returns (uint256)',
	'function balanceOf(address owner) view returns (uint256)',
    'function tokenURI(uint256 id) public view override returns (string memory)',
	//approve
	'function approve(address to, uint256 tokenId) public',
	'function getApproved(uint256 tokenId) public view returns (address)',
]

export const ABI_APP1 = [
    // 'function check(uint256[] memory tokenIds) external',

	'function transfer(bytes memory signature,address to,uint256 amount,uint256 deadline,uint256[] memory tokenIds) external',

    'function totalSupply() public view returns (uint256)',
    'function balanceOf(address _to) public view returns (uint256)',
    'function isTokenIdUnique(uint256[] memory tokenIds) public pure returns (bool)',
    'function isTokenIdUsed(uint256 tokenId) public view returns (bool)',

	"function lockDays() public view returns (uint)",
    "function totalRelease() public view returns (uint256)",
    "function getReward() public view returns (uint256)",
    "function usedToken(uint256) public view returns (uint256)",
    "function totalSupply() public view returns (uint256)"
]


export const conn = async () => {
	let provider //, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
		const network = await provider.getNetwork()
		const chainId = network.chainId
		return {
			provider,
			chainId
		}
	} catch (error) {
		console.error(error)
		throw error
	}
}

export const isInWeb3Browser = () => {
	return window.ethereum != null || window.web3 != null
}


// connect to the metamask or other web3 wallet with ethers.js
export const connect = async () => {
	let provider, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
	} catch (error) {
		// Dialog.confirm({ message: 'Please install Metamask Or open In Metamask/TokenPocket APP' }).then(() => {
		// 	location.href = 'https://metamask.io/'
		// })
		console.error(error)
		throw error
	}
	try {
		res = await provider.send('eth_requestAccounts', [])
	} catch (error) {
		console.error(error)
		return;
	}
	try {
		blocknumber = await provider.getBlockNumber()
	} catch (error) {
		console.error(error)
		return;
	}

	const network = await provider.getNetwork()
	const chainId = network.chainId

	return {
		provider,
		blocknumber,
		chainId,
		address: res[0]
	};
}


// 账号切换
export function onAccountChange(options) {
	// console.log(window.ethereum)
	window.ethereum.on('accountsChanged', async (accounts) => {
		options.success(accounts)
	})
}

// 链切换
export function onChainSwitch(options) {
	window.ethereum.on('chainChanged', async (chainId) => {
		options.success(chainId)
	});
}


export async function getERC20Info(provider, ct, address) {
	let contract = new ethers.Contract(ct, ABI_ERC20, provider)
	let decimals = await contract.decimals()
	let symbol = await contract.symbol()
	if (address) {
		let balance = await contract.balanceOf(address)
		let balanceInEther = ethers.formatUnits(balance, decimals)
		return {
			decimals,
			symbol,
			balance,
			balanceInEther,
			contract: contract
		}
	} else {
		return {
			decimals,
			symbol,
			contract: contract
		}
	}
}

/**
 * 
 * @param {
 *   chainId: CHAIN_ID,
 *   chainName: CHAIN_NAME,
 *   symbol: CHAIN_SYMBOL,
 *   decimals: 18,
 *   rpcUrls: [RPC],
 *   explorer: CHAIN_EXPLORER
 * } chain 
 * @param {*} callback 
 */
export async function switchNetwork(chain, callback) {

	// decimal to hex
	const targetChainId = '0x' + chain.chainId.toString(16)

	window.ethereum && window.ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [
			{
				chainId: targetChainId
			}
		],

	}).then(() => {
		console.log('Switch success')
		if (typeof callback === 'function') {
			callback();
		}
		// Notify({ type: 'success', message: 'Network switch successful' });
		// alert('Network switch successful');
	}).catch(e => {

		if (!window.confirm('Error: ' + e.message + ',' + 'Do you want to add ' + chain.name + ' into your network configuration?')) {
			return;
		}

		window.ethereum && window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [{
				chainId: targetChainId,
				chainName: chain.chainName,
				nativeCurrency: {
					name: chain.symbol,
					symbol: chain.symbol,
					decimals: chain.decimals
				},
				rpcUrls: chain.rpcUrls,
				blockExplorerUrls: [chain.explorer]
			}]
		}).then(() => {
			console.log('network add success')
			alert('Network add successful');
			if (typeof callback === 'function') {
				callback();
			}
		}).catch(e => {
			console.error(e)
			alert('ERROR:' + e.message);
		})

	})
}



export function watchTxRecp(timer, options) {
	if (timer) {
		clearInterval(timer)
	}
	timer = setInterval(() => {
		let new_options = { txid: options.txid, provider: options.provider }

		new_options.success = (recp) => {
			clearInterval(timer)

			if (typeof options.success === 'function') {
				options.success(recp)
			}
		}

		new_options.failed = (recp) => {
			clearInterval(timer)
			if (typeof options.failed === 'function') {
				options.failed(recp)
			}
		}

		new_options.process = () => {
			if (typeof options.process === 'function') {
				options.process()
			}
		}

		getTxReceipt(new_options)

	}, 800);
}

export function getTxReceipt(options) {
	options.provider.getTransactionReceipt(options.txid).then((recp) => {
		console.log("recp = ")
		console.log(recp)
		if (recp) {

			if (recp.status === 1) {
				// 事件成功了

				if (typeof options.success === 'function') {
					options.success(recp);
				}
			} else if (recp.status === 0) {
				// 事件失败了
				if (typeof options.failed === 'function') {
					options.failed(recp);
				}
			}
		} else {
			if (typeof options.process === 'function') {
				options.process()
			}
		}
	}).catch(e => {
		// 中途报错了
		if (typeof options.error === 'function') {
			options.error(e);
		}
	})
}