import React from 'react';
import Header from '../../components/common/Header';
import { useTranslation } from 'react-i18next';
import { ethers } from "ethers";
import { isInWeb3Browser, connect, switchNetwork, onAccountChange } from "../../common/dapp.js";
import { CHAIN_EXPLORER, CHAIN_ID, CHAIN_NAME, CHAIN_SYMBOL, EGGX_APP1_CONTRACT, NFT_CONTRACT, RPC } from "../../common/constant.js";
import { t } from 'i18next';

import '../../i18n.js'
import { FormControl } from 'react-bootstrap';

// copy
import copy from 'copy-to-clipboard';

const ABI = [
    'function parent(address addr) view returns (address)',
    'function kids(address _addr) external view returns(address[])',
    'function addressToInviteCode(address addr) view returns (bytes)',
    'function bindReferrer(bytes memory referrerCode) external'
]

class _InvitePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            showRule: false,
            chainId: 0,
            parent: '',
            children: [],
            myInviteCode: '',
            loading: false,
            showDialog: false,
            inviteCode: null
        }
    }

    onLoad = async () => {
        const isInWeb3 = isInWeb3Browser();
        this.setState({ isInWeb3 });
        if (isInWeb3) {
            const options = await connect();
            this.setState({
                provider: options.provider,
                address: options.address,
                chainId: options.chainId,
            });

            onAccountChange({
                success: (accounts) => {
                    this.setState({
                        address: accounts[0]
                    });
                }
            });

            if (options.chainId != CHAIN_ID) {
                if (window.confirm("You are on the wrong network, do you want to switch to " + CHAIN_NAME + "?")) {
                    switchNetwork({
                        chainId: CHAIN_ID,
                        chainname: CHAIN_NAME,
                        symbol: CHAIN_SYMBOL,
                        decimals: 18,
                        rpcUrls: [RPC],
                        explorer: CHAIN_EXPLORER
                    }, () => {
                        this.onLoad();
                    });
                }
            } else {
                // 
                this.initData(options)
            }
        }
    }

    async componentDidMount() {
        this.onLoad();
    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 8) + '...' + address.substr(-6);
        } else {
            return '';
        }
    }

    bindParent = async (code) => {
        // code must be alphanumeric and equal to 8
        const t = this.props.t;
        if (!code || code.length != 8 || !/^[a-zA-Z0-9]*$/.test(code)) {
            alert(t('App11Error21'));
            return;
        }

        this.setState({
            loading: true,
        });

        try {
            const options = await connect();
            const signer = await options.provider.getSigner();
            const contract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI, signer);
            // const referrerCode = ethers.encodeBytes32String(code);
            console.log(ethers.toUtf8Bytes(code));
            const refCode = ethers.hexlify(ethers.toUtf8Bytes(code));
            console.log('refCode', refCode);
            const tx = await contract.bindReferrer(refCode);
            await tx.wait(1);
            this.initData(options);
        } catch (e) {
            console.error(e);
            alert('' + (e.reason || e.message || e))
        } finally {
            this.setState({
                loading: false,
            });

        }

    }

    initData = async (options) => {
        try {
            const address = options.address;
            const contract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI, options.provider);
            const parent = await contract.parent(address);
            const children = await contract.kids(address);
            const _myInviteCode = await contract.addressToInviteCode(address);

            // const myInviteCode = ethers.utils.parseBytes32String(_myInviteCode);
            // decodeBytes32String
            const myInviteCode = _myInviteCode && _myInviteCode != '0x' ? ethers.decodeBytes32String(ethers.zeroPadBytes(_myInviteCode, 32)) : undefined;

            this.setState({
                parent,
                children,
                myInviteCode,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {

        const styleTitle = {
            "fontSize": "1.2rem",
            "fontWeight": "bold",
            "color": "#ffffff",
            "lineHeight": "2",
        }

        const stylePlaceholder = {
            "fontSize": "1.1rem",
            "color": "rgba(255, 255, 255, 0.4)",
            "lineHeight": "2",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }

        const styleTips = {
            "fontSize": "1rem",
            "color": "rgba(255, 255, 255, 0.4)",
            "lineHeight": "2",
        }

        return (
            <div style={{
                height: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <div style={{
                    maxWidth: "600px",
                }}>
                    <div>
                        <Header address={this.state.address} title="App1" t={this.props.t} i18n={this.props.i18n} onClick={
                            () => {
                                this.onLoad();
                            }
                        } chainId={this.state.chainId} />
                    </div>
                    <div style={{
                        padding: "1rem",
                    }}>
                        <div>
                            <div style={styleTitle}>{t('App11Label1')}</div>
                            {this.state.parent && this.state.parent != '0x0000000000000000000000000000000000000000' ? <div>
                                {this.shortAddress(this.state.parent)}
                            </div> :
                                <div style={{
                                    ...stylePlaceholder
                                }} onClick={(e) => {
                                    // code from prompt input
                                    // const code = prompt(t('App11Prompt1'));
                                    // if (code) {
                                        // const _code = code.trim().toUpperCase();
                                        // this.bindParent(_code);
                                    // }
                                    this.setState({
                                        showDialog: true,
                                    });
                                }}>
                                    <div>
                                        {t('App11Label2')}
                                    </div>
                                    <img src={require('../../assets/icons/rightarrow.png')} style={{
                                        width: '1rem',
                                        height: '1rem',
                                    }}></img>
                                </div>}
                            <div style={styleTips}>
                                {t('App11Label3')}
                            </div>
                        </div>
                        <div>
                            <div style={styleTitle}>{t('App11Label4')}</div>
                            {this.state.myInviteCode && this.state.myInviteCode != '0x' ? <div style={styleTitle}>
                                {this.state.myInviteCode} <img src={require('../../assets/icons/copy.png')} style={{
                                    width: '1.2rem',
                                    height: '1.2rem',
                                    cursor: 'pointer',
                                    marginLeft: '0.5rem',
                                }} onClick={(e) => {
                                    copy(this.state.myInviteCode);
                                }}></img>
                            </div> :
                                <div style={stylePlaceholder} onClick={(e) => {
                                    // to root
                                    if (this.state.parent && this.state.parent != '0x0000000000000000000000000000000000000000') {
                                        window.location.href = '/';
                                    }
                                }}>
                                    {t('App11Label5')} {this.state.parent && this.state.parent != '0x0000000000000000000000000000000000000000' ? <div>
                                        {t('App11Tip1')} <img src={require('../../assets/icons/rightarrow.png')} style={{
                                            width: '1rem',
                                            height: '1rem',
                                        }}></img>
                                    </div> : null}
                                </div>}
                            <div style={styleTips}>
                                {t('App11Tip2')}
                            </div>
                        </div>
                        <div>
                            <div style={styleTitle}>{t('App11Tip3')}</div>
                            <div style={styleTips}>
                                {t('App11Tip4')}
                            </div>
                        </div>
                        <div>
                            <div style={styleTitle}>{t('App11Tip5')}</div>
                            <div>
                                {/* kids */}
                                {this.state.children.map((child) => {
                                    return <div key={child} style={{
                                        color: "#ffffff",
                                        textDecoration: "underline",
                                        fontSize: "1.2rem",
                                    }}>{this.shortAddress(child)}</div>
                                })}
                            </div>
                        </div>
                    </div>
                    {/* input dialog promot model */}
                    {this.state.showDialog ? <div style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        background: 'rgba(0,0,0,0.5)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '100',
                        display: 'flex',
                    }}>
                        <div style={{
                            width: '80%',
                            maxWidth: '400px',
                            background: 'white',
                            padding: '1rem',
                            borderRadius: '0.5rem',
                        }}>
                            <div style={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                color: '#333',
                                marginBottom: '1rem',
                            }}>
                                {t('App11Label8')}
                            </div>
                            <div >
                                {/* <input type="text" id="input" style={{
                                width: '100%',
                                height: '3rem',
                                fontSize: '1rem',
                                padding: '0.5rem',
                                borderRadius: '0.5rem',
                                // color
                                backgroundColor: '#f8f9fa',
                                color: '#333',
                            }}></input> */}

                                <FormControl
                                    aria-label={t('App11Label8')}
                                    aria-describedby="basic-addon2"
                                    value={this.state.inviteCode ?? ''}
                                    size="lg"
                                    isInvalid={this.state.inviteCode && !/^[a-zA-Z0-9]{8}$/.test(this.state.inviteCode)}
                                    isValid={this.state.inviteCode && /^[a-zA-Z0-9]{8}$/.test(this.state.inviteCode)}
                                    type="text"
                                    style={{
                                        backgroundColor: '#f8f9fa',
                                        color: '#333',
                                    }}
                                    onChange={(e) => {
                                        
                                        this.setState({
                                            inviteCode: e.target.value.toUpperCase(),
                                        });
                                    }}
                                />
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: '1rem',
                            }}>
                                <div style={{
                                    color: 'red',
                                    fontSize: '1.2rem',
                                }} onClick={(e) => {
                                    this.setState({
                                        showDialog: false,
                                    });
                                }}>{t('App11Label7')}</div>

                                <div style={{
                                    color: 'blue',
                                    fontSize: '1.2rem',
                                }} onClick={(e) => {
                                    // 
                                    if (!this.state.inviteCode ) {
                                        alert(t('App11Error21'));
                                        return;
                                    }
                                    this.bindParent(this.state.inviteCode.toUpperCase());
                                    this.setState({
                                        showDialog: false,
                                    });
                                }}>{t('App11Label6')}</div>

                            </div>
                        </div>
                    </div> : null}

                    {/* loading mask */}
                    {this.state.loading && <div style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '2rem',
                    }}>
                        {/* center text white */}
                        <div>Loading...</div>
                    </div>}

                </div> </div>
        );
    }
}

function InvitePage() {
    const { t, i18n } = useTranslation();
    return <_InvitePage t={t} i18n={i18n} />;
}

export default InvitePage;