import React from "react";
import { useTranslation } from 'react-i18next';
import { CHAIN_ID, CHAIN_NAME } from "../../common/constant";

import '../../i18n.js'

class _Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showLanguage: false,
        }

    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 6) + '...' + address.substr(-4);
        }
    }

    onChange = (lang) => {
        this.setState({
            showLanguage: false,
        })
        this.props.i18n.changeLanguage(lang);
    }

    render() {
        const styleLangRow = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '3.6rem',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#0B57FF',
        };
        const { t } = this.props;
        return (
            <div style={{
                // backgroundColor: "#f8f9fa",
                // borderBottom: "1px solid #e9ecef",
            }}>
                <div style={{
                    padding: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    {/* select language */}
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <img src={require('../../assets/icons/logo192.png')} style={{
                            width: "2rem",
                            height: "2rem",
                            // transform: this.state.showLanguage ? 'rotate(180deg)' : 'rotate(0deg)',
                        }} onClick={(e)=> {
                            window.location.href = '/';
                        }}></img>
                        <div style={{
                            width: '0.6rem'
                        }}></div>

                        <img src={require('../../assets/icons/lang1.png')} style={{
                            width: "2rem",
                            height: "2rem",
                            cursor: "pointer",
                        }} onClick={
                            () => {
                                this.setState({
                                    showLanguage: !this.state.showLanguage,
                                })
                            }
                        }
                        ></img>
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {this.props.address ? <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "14px",
                                fontWeight: "bold",
                                lineHeight: "2.2",
                                backgroundColor: "#333333",
                                color: "#f8f9fa",
                                paddingLeft: "1.5rem",
                                paddingRight: "1.5rem",
                                borderRadius: "5rem",

                            }}>{CHAIN_ID == this.props.chainId ? CHAIN_NAME : 'Not Supported'} - {this.shortAddress(this.props.address)}</div> :
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    lineHeight: "2.2",
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: "1.5rem",
                                    paddingRight: "1.5rem",
                                    borderRadius: "5rem",

                                }} onClick={(e) => {
                                    this.props.onClick()
                                }}>
                                    Connect 
                                </div>
                            }
                        </div>
                        {this.props.showInvite ? <div style={{ marginLeft: '1rem'}} onClick={(e) => {
                            // to /invite
                            window.location.href = '/invite';
                        }}>
                            {/* <img src={require('../../assets/icons/info.png')} style={{
                                width: "2rem",
                                height: "2rem",
                            }} onClick={this.props.onShowRule}></img> */}
                            {t('App11InviteButton')}
                        </div> : <div ></div> }
                    </div>
                </div>
                <div style={{ zIndex: 999999, display: this.state.showLanguage ? 'flex' : 'none', flexDirection: 'column', position: 'absolute', top: '50px', right: '0px', width: '100%', backgroundColor: '#ffffff', borderRadius: '8px', padding: '10px', border: '1px solid #999999' }}>
                    <div onClick={(e) => { this.onChange('en') }}
                        style={styleLangRow}>
                        English
                    </div>
                    <div onClick={(e) => {
                        this.onChange('id')
                    }} style={styleLangRow}>
                        Bahasa
                    </div>
                    <div onClick={(e) => {
                        this.onChange('cn')
                    }} style={styleLangRow}>
                        中文简体
                    </div>
                    {/* 西班牙语 */}
                    <div onClick={(e) => {
                        this.onChange('es')
                    }} style={styleLangRow}>
                        Español
                    </div>

                    {/* 法语 */}
                    <div onClick={(e) => {
                        this.onChange('fr')
                    }} style={styleLangRow}>
                        Français
                    </div>

                    {/* 德语 */}
                    <div onClick={(e) => {
                        this.onChange('de')
                    }
                    } style={styleLangRow}>
                        Deutsch
                    </div>

                    {/* 日语 */}
                    <div onClick={(e) => {
                        this.onChange('ja')
                    }
                    } style={styleLangRow}>
                        日本語
                    </div>

                    {/* 韩语 */}
                    <div onClick={(e) => {
                        this.onChange('ko')
                    }
                    } style={styleLangRow}>
                        한국어
                    </div>

                </div>
            </div>
        );
    }
}

function Header(props) {
    const { t, i18n } = useTranslation();
    return (
        <_Header showInvite={props.showInvite} title={props.title || t('AppName')} t={t} i18n={i18n} address={props.address} chainId={props.chainId} onClick={props.onClick} onShowRule={props.onShowRule} />
    );
}

export default Header;